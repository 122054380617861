import React, { useRef, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import ReactPlayer from 'react-player'
import { StaticImage } from 'gatsby-plugin-image'
import {  Trans } from 'gatsby-plugin-react-i18next'



import { Timeline } from 'react-twitter-widgets'

import { gsap } from 'gsap'


const PageEvento = (props) => {

  const [isReady, setIsReady] = useState(false)

  const playerRef = useRef()

  useEffect(() => {
    isReady &&
      playerRef.current &&
      gsap.to(playerRef.current, { autoAlpha: 1, delay: 0.5 })
  }, [isReady])



   const playerConfig = {
     vimeo: {
       autoplay: true,
       loop: true,
       muted: true,

     },
   }

  return (
    <Layout location={props.location}>
      <Helmet bodyAttributes={{ class: 'page-header-black-alt' }}></Helmet>
      <section className='relative flex flex-col py-40 bg-black text-green'>
        <div className='container  z-[1] grid gap-4 pt-20 mx-auto lg:gap-16 header-challenge'>
          <span className='px-4 text-sm text-center lg:text-xl lg:text-left header-challenge__text-top lg:px-0 lg:pr-4'>
            <Trans>Programa de Packaging Sostenible</Trans>
          </span>

          <div className='grid grid-cols-4 gap-4'>
            <div
              ref={playerRef}
              className='w-full col-span-3 transition-opacity duration-700 delay-100 opacity-0 transform-gpu player-wrapper'
            >
              <ReactPlayer
                url='https://vimeo.com/564392223'
                controls={true}
                className=' react-player'
                width='100%'
                height='100%'
                config={playerConfig}
                onReady={() => {
                  setIsReady(true)
                }}
              />
            </div>
            <div className='col-span-1'>
              <Timeline
                dataSource={{
                  sourceType: 'collection',
                  id: '1409604288867938308',
                }}
                options={{
                  height: '480',
                  theme: 'dark',
                  borderColor: '#33D87B',
                  chrome: 'noheader, nofooter',
                  dnt: true,
                }}
              />
            </div>
          </div>

          <span className='px-4 text-sm text-center lg:text-left lg:text-xl header-challenge__text-bottom lg:px-0 lg:pr-4'>
            <Trans>Imaginando el futuro de nuestras fibras</Trans>
          </span>
        </div>

        <div className='absolute z-0 transform -translate-x-1/2 -bottom-20 left-1/2'>
          <StaticImage
            style={{
              gridArea: '1/1',
            }}
            layout='fixed'
            width={1530}
            src='../images/bg-savia-txt-beige.png'
            alt=''
          />
        </div>

      </section>
    </Layout>
  )
}

export default PageEvento

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "index", "about"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`